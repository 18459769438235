import { render, staticRenderFns } from "./MiniArea.vue?vue&type=template&id=45020084&scoped=true"
import script from "./MiniArea.vue?vue&type=script&lang=js"
export * from "./MiniArea.vue?vue&type=script&lang=js"
import style0 from "./MiniArea.vue?vue&type=style&index=0&id=45020084&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45020084",
  null
  
)

export default component.exports